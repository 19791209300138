import React, {useEffect, useState} from 'react'
import { Card, CardActions, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow} from "@material-ui/core";
import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import { LinkGroupDto, getLatestLinkGroup, findLinkGroups, countLinkGroups, getPDFByLinkGroupId } from '../../api/LinkGroupApi';
import ProductStandardLinkingDetail from './ProductStandardLinkingDetail';
import TableHeaderCellStyled from '../common/TableHeaderCellStyled';
import ButtonStyled from '../common/ButtonStyled';
import BackendError from '../common/BackendError';
import Header from '../common/Header';
import { navigate } from 'gatsby-link';
import { findProductById, ProductDto } from '../../api/ProductApi';
import fileDownload from 'js-file-download';

interface ProductStandardLinkingHistoryProps{
    productId: number,
    style?: any,
    showLatest?:boolean
}
export default function ProductStandardLinkingHistory( props: ProductStandardLinkingHistoryProps){
    const classes = useStyles();
    const { t } = useTranslation();

    const [backendErrorMessage, setBackendErrorMessage] = useState(null)

    const [currentLinkGroup, setCurrentLinkGroup] = useState<LinkGroupDto>(null)
    const [totalLinkGroupCount, setTotalLinkGroupCount] = useState<number>(0);
    const [rows, setRows] = useState<LinkGroupDto[]>([]);
    const [rowsPerPageLimit, setRowsPerPageLimit] = useState<number>(10);
    const [currentPageOffset, setCurrentPageOffset] = useState<number>(0);
    const [allLinkGroups, setAllLinkGroups] = useState<LinkGroupDto[]>([]);
    const [newestFirst, setNewestFirst] = useState<boolean>(false);
    const [currentProduct, setCurrentProduct] = useState<ProductDto>(null)

    const getCurrentRows = (rowsPerPage, currentPage, newestFirst) => {
        findLinkGroups(2, rowsPerPage, currentPage*rowsPerPage, props.productId, newestFirst).then(data => {
            countLinkGroups(props.productId).then(countData => {
                setTotalLinkGroupCount(countData)
            }).catch(err => console.log("Error getting total linkgroups count ",err))
            setRows(data)
        }).catch(err => console.log("Error getting linkgroup rows ",err))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var limit = parseInt(event.target.value, 10)
        getCurrentRows(limit, 0, newestFirst)
        setRowsPerPageLimit(limit);
        setCurrentPageOffset(0)
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        getCurrentRows(rowsPerPageLimit, newPage, newestFirst)
        setCurrentPageOffset(newPage)
    };

    const handleCreatedSort = (value: boolean) => {
        getCurrentRows(rowsPerPageLimit, currentPageOffset, value)
        setNewestFirst(value)
    }

    const downloadPdf = () => {
        getPDFByLinkGroupId(7,0).then(data =>{
            fileDownload(data, "lol.pdf");
        }).catch(err =>{
            console.log(err)
        })
    }

    useEffect(() => {
        findProductById(props.productId).then(data => {
            setCurrentProduct(data)
        }).catch(err => {
            console.log("Somethign went wrong")
        })
        if(new URLSearchParams(location.search).has("latest") || props.showLatest){
            getLatestLinkGroup(2,props.productId).then(data => {
                setCurrentLinkGroup(data)
            }).catch(err => {
                (err.response!=null && err.response.data.message!=null) ? setBackendErrorMessage(err.response.data.message) : setBackendErrorMessage(t("ERRDATAERROR"))
            })
        }
        getCurrentRows(rowsPerPageLimit, currentPageOffset, newestFirst)
    },[])

    return (
        <Paper className={classes.root} style={props.style}>
            <Card variant="outlined">
                <Header title={t("LINKHISTORY")} subheader={currentProduct?.name}/>
                {backendErrorMessage!=null && <BackendError error={backendErrorMessage}/>}
                {(currentLinkGroup!=null) ? 
                <div>
                    <ProductStandardLinkingDetail linkGroup={currentLinkGroup}/>
                </div>
                : 
                <div>
                    <Table aria-label={t("PRODUCTSEARCHTABLE")}>
                        <TableHead>
                            <TableRow key="linkgrouptable">
                                <TableHeaderCellStyled key={"created"} width={"25%"} label={t("LINKINGCREATEDDATE")} sort={true} sortValue={newestFirst} handleSort={handleCreatedSort}/>
                                <TableHeaderCellStyled key={"username"} width={"25%"} label={t("AUTHOR")}/>
                                <TableHeaderCellStyled key={"standardsIncluded"} width={"25%"} label={t("STANDARDSINCLUDEDCOUNT")}/>
                                <TableHeaderCellStyled key={"standardsRemoved"} width={"25%"} label={t("STANDARDSREMOVEDCOUNT")}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow style={{cursor:"pointer"}} key={row.created} hover>
                                    <TableCell onClick={()=>setCurrentLinkGroup(row)}>{row.created}</TableCell>
                                    <TableCell onClick={()=>setCurrentLinkGroup(row)}>{row.username}</TableCell>
                                    <TableCell onClick={()=>setCurrentLinkGroup(row)}>{row.standards.length}</TableCell>
                                    <TableCell onClick={()=>setCurrentLinkGroup(row)}>{row.removedStandards.length}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="products-search-footer">
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 30, { label: t("ALL"), value: 9999 }]}
                                    style={{}}
                                    count={totalLinkGroupCount}
                                    rowsPerPage={rowsPerPageLimit}
                                    page={currentPageOffset}
                                    SelectProps={{
                                        inputProps: { 'aria-label': t("ROWSPERPAGE") },
                                        native: true,
                                    }}
                                    labelRowsPerPage={t("ROWSPERPAGE")}
                                    labelDisplayedRows={({ from, to, count }) =>from + '-' + to + t("ROWSOF") + count}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
                }
                {currentLinkGroup!=null ?
                    <CardActions className={classes.cardActions}>
                        <ButtonStyled
                            style={{marginRight: "auto"}}
                            text={t("BACKTOLINKHISTORY")}
                            onClick={() => setCurrentLinkGroup(null)}/>
                        <ButtonStyled
                        style={{backgroundColor:"grey"}}
                            text={t("GENERATEPDF")}
                            onClick={()=>{}}/>
                    </CardActions>
                :
                    <CardActions className={classes.cardActions}>
                        <ButtonStyled
                            style={{marginRight: "auto"}}
                            text={t("BACK")}
                            onClick={() => navigate("/products/"+props.productId)}/>
                    </CardActions>
                }
            </Card>
        </Paper>
    )
}