import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";
import { stylesConfig } from "../config/StylesConfig";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    //Common
    root: {
        width: stylesConfig.contentWidth,
        padding: 20,
    },
    //Filters
    icodeFilter:{
        marginBottom: 20,
        marginRight: "auto",
    },
    flexBreak:{
        flexBasis: "100%",
        height: 0,
        width: 0,
    },
    //Fields and forms
    fieldTitle:{
        fontWeight: "bold",
        color: theme.palette.secondary.dark,
        fontSize: 17
    },
    fieldContent:{
        marginLeft: 10,
        marginBottom: 10,
        paddingRight: 20
    },
    formErrors:{
        color: theme.palette.error.main
    },
    filterContainer:{
        display: "flex",
        justifyContent: "flex-end",
        flexGrow: 1,
        flexFlow: "row wrap"
    },
    cardActions:{
        display: "flex",
        justifyContent: "flex-end",
        flexGrow: 1,
        flexFlow: "row wrap"
    },
}),{defaultTheme: theme});

export { useStyles };

