import React from 'react';
import Layout from '../../../components/Layout';

import ProductStandardLinkingHistory from '../../../components/products/ProductStandardLinkingHistory';

export default function ProductStandardLinkingHistoryPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <ProductStandardLinkingHistory showLatest={false} style={{minWidth:700, width:"70%", marginLeft: "auto", marginRight: "auto"}} productId={props.params.productId}/>
        </Layout>
    )
}